import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  const renderPageHeading = () => {
    if (location.pathname === rootPath) {
      return <h1 class="page-header">Tech @ Up Learn</h1>
    } else {
      return <h1 className="page-header">{title}</h1>
    }
  }

  const renderPageBreadCrumbs = () => {
    if (location.pathname === rootPath) {
      return (
        <ol class="breadcrumb">
          <li>
            <a href="/">Up Learn</a>
          </li>
          <li class="active">Tech</li>
        </ol>
      )
    } else {
      return (
        <ol class="breadcrumb">
          <li>
            <a href="/">Up Learn</a>
          </li>
          <li class="active">{title}</li>
        </ol>
      )
    }
  }

  const renderFooter = () => (
    <footer>
      <div className="row">
        <div className="col-lg-12">
          <p className="footerDivider"></p>
          <p>
            Copyright © <a href="mailto:team@uplearn.co.uk">Up Learn Limited</a>{" "}
            2020. Registered company in England and Wales (number: 10010657).
            VAT Reg. No GB 249 7670 58.
          </p>
          <p></p>
          <p>
            <a href="/">Up Learn</a> |{" "}
            <a
              className="btn-social-icon btn-facebook"
              href="https://www.facebook.com/getuplearn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>{" "}
            |{" "}
            <a
              class="btn-social-icon btn-twitter"
              href="https://twitter.com/up_learn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>{" "}
            |
            <a href="https://uplearn.co.uk/raise-achievement/" target="">
              Teachers{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/pricing/" target="">
              Pricing{" "}
            </a>
            |{" "}
            <a href="https://uplearn.workable.com/" target="">
              Careers{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/scholarships/" target="">
              Scholarships{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/faq/" target="">
              About &amp; FAQ{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/guarantee/" target="">
              A*-A Guarantee{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/blog/" target="">
              Blog{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/terms" target="">
              Terms &amp; Conditions{" "}
            </a>
            |{" "}
            <a href="https://uplearn.co.uk/privacy/" target="">
              Privacy{" "}
            </a>
          </p>
        </div>
      </div>
    </footer>
  )

  return (
    <div className="container">
      {renderPageHeading()}
      {renderPageBreadCrumbs()}
      <main className="posts-container">{children}</main>
      {renderFooter()}
    </div>
  )
}

export default Layout
